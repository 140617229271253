<template>
  <div class="resource">
    <div class="allShrink">
      <div class="header-banner">
        <div class="login-area" v-if="!showLogin">
          <login-area></login-area>
        </div>
        <div class="swiper">
          <el-carousel
            class="carousel"
            :interval="4000"
            :loop="true"
            arrow="always"
            height="500px"
          >
            <el-carousel-item>
              <p class="slogan">沉睡资产变增收活水</p>
              <img src="../../assets/imgs/banner3.png" alt="" />
            </el-carousel-item>
            <el-carousel-item>
              <p class="slogan">闲置资源变产业活水</p>
              <img src="../../assets/imgs/banner3-1.png" alt="" />
            </el-carousel-item>
            <el-carousel-item>
              <p class="slogan three">资源资产引入金融活水</p>
              <img src="../../assets/imgs/banner3-2.png" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>

      <!-- 快速入口 -->
      <!-- <r-quick-link></r-quick-link> -->

      <!-- 竞价大厅 -->
      <resource-hall></resource-hall>

      <!-- 成果展示 -->
      <achieve-display></achieve-display>

      <!-- 交易流转动态 -->
      <!-- <production-performance></production-performance> -->

      <!-- 交易流转统计 -->
      <landtrust-statistics></landtrust-statistics>
    </div>
  </div>
</template>

<script>
import { getStore } from "@/js/utils/store";
import LoginArea from "@/components/LoginArea";
import RQuickLink from "./components/RQuickLink.vue";
import ResourceHall from "../homepage/components/ResourceHall.vue";
import AchieveDisplay from "./components/AchieveDisplay.vue";
import ProductionPerformance from "./components/ProductionPerformance.vue";
import LandtrustStatistics from "./components/LandtrustStatistics.vue";

export default {
  metaInfo: {
    title: window.pageConfiguration.company.title,
    meta: [
      { name: "description", content:  window.pageConfiguration.company.description },
      { name: "keywords", content: window.pageConfiguration.company.keywords2 },
    ],
  },
  components: {
    LoginArea,
    RQuickLink,
    ResourceHall,
    AchieveDisplay,
    ProductionPerformance,
    LandtrustStatistics,
  },

  data() {
    return {
      showLogin: getStore({ name: "access_token" }),
    };
  },
};
</script>

<style lang="less" scoped>
.resource {
  background: #fff;
  text-align: left;
  display: grid;
  .allShrink {
    width: 90%;
    margin: auto;
  }
}
</style>
